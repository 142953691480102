import React, { useMemo } from "react";

import DropdownMenuItemWrapper from "./item.styles";

import { TbRotateClockwise2 } from "react-icons/tb";

import { IDropdownMenuItemProps } from "./item.interfaces";

const DropdownMenuItem = ({
  id,
  className,
  children,
  onClick,
  icon,
  active,
  disabled,
  isLoading,
  as,
}: IDropdownMenuItemProps) => {
  const classes = useMemo(() => {
    const data: string[] = [];

    if (className) {
      data.push(className);
    }

    if (active) {
      data.push("active");
    }

    if (disabled || isLoading) {
      data.push("disabled");
    }

    return data.join(" ");
  }, [className, active, disabled, isLoading]);

  return (
    <DropdownMenuItemWrapper
      as={as}
      id={id || undefined}
      onClick={onClick && !disabled && !isLoading ? onClick : undefined}
      className={classes || undefined}
    >
      {isLoading ? (
        <TbRotateClockwise2 className="rotate-animation" />
      ) : icon ? (
        React.createElement(icon)
      ) : null}

      {children}
    </DropdownMenuItemWrapper>
  );
};

export default DropdownMenuItem;
