import sidebarIcons from "../common/data/icons";

const getIcon = (icon?: string | null, source = sidebarIcons) => {
  if (icon) {
    const newIcon = source.find(({ id }) => id === icon);

    if (newIcon?.icon) {
      return newIcon.icon;
    }
  }

  return null;
};

export default getIcon;
