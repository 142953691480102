import { useApolloClient } from "shared/providers/apollo/apollo.package";
import { useHistory } from "shared/providers/router/router.package";

import { resetPortfolioVar } from "../common/apollo/vars/portfolio.var";
import { resetPortfolioSwitchingVar } from "../common/apollo/vars/portfolio-switching.var";
import {
  resetDockRightPositionVar,
  resetDockVar,
} from "../common/apollo/vars/dock.var";
import { resetGlobalModalVar } from "../common/apollo/vars/global-modal.var";
import { resetSidebarExpandedVar } from "../common/apollo/vars/sidebar.var";
import { resetTitleVar } from "../common/apollo/vars/title.var";

import { WEB_AUTH_ADMIN_TOKEN } from "shared/common/data/constants";

import { removeLocalStorageItem } from "shared/utils/local-storage";

/**
 * Logs out the user by resetting the cached store and removes auth token from localStorage
 * @returns async logout function
 */

const useLogout = () => {
  const history = useHistory();
  const client = useApolloClient();

  const logout = async () => {
    removeLocalStorageItem(WEB_AUTH_ADMIN_TOKEN);

    await client.clearStore();

    resetPortfolioVar();
    resetPortfolioSwitchingVar();

    resetDockVar();
    resetDockRightPositionVar();

    resetGlobalModalVar();

    resetSidebarExpandedVar();

    resetTitleVar();

    history.push("/login");
  };

  return logout;
};

export default useLogout;
