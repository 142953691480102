import { InMemoryCache } from "shared/providers/apollo/apollo.package";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getRecordActivityLog: {
          keyArgs: [
            "activityType",
            "referType",
            "recordId",
            "sortBy",
            "sortByDir",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getBuildings: {
          keyArgs: [
            "portfolioId",
            "search",
            "propertyIds",
            "active",
            "sortBy",
            "sortByDir",
            "@connection",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getPortfolios: {
          keyArgs: ["search", "active", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getGlobalUsers: {
          keyArgs: ["search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getProperties: {
          keyArgs: ["portfolioId", "search", "active", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCompanies: {
          keyArgs: [
            "portfolioId",
            "propertyIds",
            "companyTypes",
            "search",
            "active",
            "@connection",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCois: {
          keyArgs: [
            "portfolioId",
            "statuses",
            "search",
            "from",
            "to",
            "propertyIds",
            "companyTypes",
            "companyIds",
            "createdByIds",
            "archived",
            "expiration",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getUsers: {
          keyArgs: [
            "portfolioId",
            "search",
            "propertyIds",
            "roleIds",
            "companyIds",
            "active",
            "@connection",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getRoles: {
          keyArgs: ["portfolioId", "search", "active", "@connection"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCategories: {
          keyArgs: [
            "portfolioId",
            "recordType",
            "recordLevel",
            "search",
            "active",
            "propertyIds",
            "@connection",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCategoriesGrouped: {
          keyArgs: [
            "portfolioId",
            "recordType",
            "recordLevel",
            "search",
            "active",
            "propertyIds",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getFilterableGroupedCategories: {
          keyArgs: ["portfolioId", "search", "location"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getCategoryGroups: {
          keyArgs: [
            "portfolioId",
            "recordType",
            "recordLevel",
            "search",
            "active",
            "propertyIds",
            "@connection",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getWorkOrders: {
          keyArgs: [
            "portfolioId",
            "search",
            "from",
            "to",
            "propertyIds",
            "buildingIds",
            "statusIds",
            "assigneeIds",
            "categoryIds",
            "companyIds",
            "priorityIds",
            "requestedForIds",
            "closedAtFrom",
            "closedAtTo",
            "archived",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getWorkOrderEquipment: {
          keyArgs: ["workOrderId", "search"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
      },
    },
    EmailTemplateObject: {
      keyFields: ["type"],
    },
    FileObject: {
      keyFields: false,
    },
    UserObject: {
      fields: {
        role: {
          merge: true,
        },
      },
    },
  },
});

export default cache;
