import { lazy } from "react";

import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { globalModalVar } from "../../common/apollo/vars/global-modal.var";

import withSuspense from "shared/hoc/with-suspense/with-suspense.hoc";

import { GlobalModalTypesEnum } from "../../common/enums/global-modal-types.enum";

const SingleWorkOrder = withSuspense(
  lazy(
    () =>
      import(
        "../../pages/portfolios/single-portfolio/work-orders/single-work-order/single-work-order.page"
      )
  ),
  true
);

const GlobalModals = () => {
  const globalModalData = useReactiveVar(globalModalVar);

  return globalModalData ? (
    globalModalData.type === GlobalModalTypesEnum.WORK_ORDER_TYPE ? (
      <SingleWorkOrder id={globalModalData.id} />
    ) : null
  ) : null;
};

export default GlobalModals;
