import styled from "styled-components";

import rem from "../../../utils/rem";

import { IDropdownMenuTogglerStyles } from "./toggler.interfaces";

const DropdownMenuTogglerWrapper = styled.div<IDropdownMenuTogglerStyles>`
  display: inline-block;

  .dropdown-toggler {
    font-size: ${rem(14)};
    color: ${({ $color, theme }) => $color || theme.colors.grey[600]};
    cursor: pointer;
    transition: color 0.2s ease;

    > svg {
      display: block;
    }

    &:hover {
      color: ${({ $color, theme }) => $color || theme.colors.grey[900]};
    }
  }
`;

export default DropdownMenuTogglerWrapper;
