import { setContext } from "shared/providers/apollo/link/context.package";
import { getLocalStorageItem } from "shared/utils/local-storage";

import { WEB_AUTH_ADMIN_TOKEN } from "shared/common/data/constants";

const authLink = setContext((_, { headers }) => {
  const token = getLocalStorageItem(WEB_AUTH_ADMIN_TOKEN);

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

export default authLink;
