import React, { useEffect } from "react";

import { SELECT_CLASS_NAME } from "../common/data/constants";

/**
 * Control click outside the ref
 * @param ref target which we control outside click
 * @param handler ideally useCallback function handling our outside click
 */

const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: () => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current) {
        return;
      }

      // We don't want to close our popouts for example if user clicks on Toaster or Dropdown Menu
      if (
        (event.target as Element).closest(".Toastify") ||
        (event.target as Element).closest(`.${SELECT_CLASS_NAME}__menu-portal`)
      ) {
        return;
      }

      // We should not close other popouts when something is clicked in global search
      // still, we need to close global search when its clicked outside - that's why we have additional check !ref.current.closest(".global-search")
      if (
        (event.target as Element).closest(".global-search") &&
        !ref.current.closest(".global-search")
      ) {
        return;
      }

      if (ref.current.contains(event.target as Node)) {
        return;
      }

      handler();
    };

    // We need to capture (from the top - window) as by the time event bubbles some elements are unmounted/removed.

    document.addEventListener("mousedown", listener, true);
    document.addEventListener("touchstart", listener, true);
    return () => {
      document.removeEventListener("mousedown", listener, true);
      document.removeEventListener("touchstart", listener, true);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
