import styled from "styled-components";

import { IDropdownMenuStyles } from "./dropdown-menu.interfaces";

const DropdownMenuWrapper = styled.ul<IDropdownMenuStyles>`
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  width: ${({ $width }) => $width};
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: 100%;
  max-height: ${({ $maxHeight }) => $maxHeight};
  overflow-y: ${({ $maxHeight }) => ($maxHeight ? "auto" : undefined)};
  display: block;
  box-shadow: ${({ theme }) => theme.boxShadow};
  z-index: ${({ theme }) => theme.zIndex.popouts};
`;

export default DropdownMenuWrapper;
