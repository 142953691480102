import styled, {
  css,
  keyframes,
} from "shared/providers/styled-components/styled-components.package";
import { rollIn } from "shared/providers/animations/animations.package";

import {
  DropdownMenuWrapper,
  DropdownMenuItemWrapper,
} from "shared/components/dropdown-menu";
import { Title } from "shared/components/title";

const rollAnimation = keyframes`${rollIn}`;

const openHoverSwitcherState = css`
  background-color: ${({ theme }) => theme.colors.primary.base};
  border-color: ${({ theme }) => theme.colors.primary[900]};
  color: #ffffff;

  > svg {
    color: #ffffff;
  }

  ${Title} {
    color: #ffffff;
  }

  .logo-holder {
    box-shadow: none;
  }

  &.open {
    > svg {
      transform: rotate(-180deg);
    }
  }
`;

const expendedOpenHoverState = css`
  width: ${({ theme }) => theme.layout.expandedWidth};

  .logo-holder {
    margin-right: 16px;
  }

  .property-switcher {
    justify-content: flex-start;
    padding-left: 4px;
    padding-right: 4px;

    > svg {
      display: inline;
    }

    ${Title} {
      font-size: 16px;
    }
  }

  .single-item-wrapper {
    align-items: stretch;

    &.hasSub {
      > ul {
        display: block;
      }
    }
  }

  .single-item {
    justify-content: start;
    width: 100%;
    height: auto;
    border-radius: 8px;
    background-color: transparent;

    &.active {
      background-color: ${({ theme }) => theme.colors.primary.base};
      color: #ffffff;

      &.hasCaret {
        color: ${({ theme }) => theme.colors.grey[650]};
        background-color: ${({ theme }) => theme.colors.grey[100]};
      }
    }

    &.isTitle {
      border-top: none;
    }

    &:hover:not(.active):not(.isTitle),
    &:active:not(.active):not(.isTitle),
    &:focus:not(.active):not(.isTitle) {
      background-color: ${({ theme }) => theme.colors.grey[100] || "#F5F6F7"};
    }

    > svg {
      margin-right: 12px;
    }

    > span {
      display: inline;
    }

    .caret {
      display: inline;
    }
  }

  .footer-wrapper {
    background-color: ${({ theme }) => theme.colors.grey[50] || "#FAFAFA"};
    transition: all 0.2s ease;
    transition-property: background-color;

    &::before {
      left: 0;
      right: 0;
    }
  }
`;

const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0%;
  width: ${({ theme }) => theme.layout.collapsedWidth};
  padding-top: 8px;
  z-index: ${({ theme }) => theme.zIndex.sidebar};
  border-right: 1px solid ${({ theme }) => theme.colors.grey[300]};

  .title-wrapper {
    padding: 0 8px 8px;
    display: flex;
    align-items: center;

    .close-hamburger {
      flex-shrink: 0;
      display: none;
      font-size: 30px;
      margin-right: 15px;
      cursor: pointer;
    }

    ${DropdownMenuWrapper} {
      box-shadow: none;
    }

    ${DropdownMenuItemWrapper} {
      > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &.active {
        padding-right: 34px;
        position: relative;
      }
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
      .close-hamburger {
        display: inline;
      }
    }
  }

  .logo-holder {
    border-radius: 50%;
    width: 56px;
    height: 56px;
    box-shadow: 0px 7px 20px #f2f2f2, 0px 3px 3px rgba(0, 0, 0, 0.05),
      0px 4px 9px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    > img {
      max-width: 30px;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    > svg {
      color: ${({ theme }) => theme.colors.grey[900]};
      font-size: 24px;
    }
  }

  .property-switcher {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 4px 0;
    transition: all 0.2s ease;
    transition-property: background-color, padding;
    cursor: pointer;

    ${Title} {
      font-weight: 600;
      font-size: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    > svg {
      margin-left: auto;
      display: none;
      color: ${({ theme }) => theme.colors.grey[650]};
      transition: transform 0.2s ease;
    }

    &.open {
      ${openHoverSwitcherState};
    }

    &:hover {
      ${openHoverSwitcherState};
    }
  }

  .switcher-active-indicator {
    flex-shrink: 0;
    background-color: ${({ theme }) => theme.colors.primary.base};
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.primary.base};
    color: #ffffff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .switcher-drop-logo {
    flex-shrink: 0;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.grey[300]};
    margin-right: 8px;

    > img {
      max-width: 18px;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    > svg {
      color: ${({ theme }) => theme.colors.grey[650]};
      font-size: 16px;
    }
  }

  .single-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;

    &.hasSub {
      > ul {
        display: none;
        margin-left: 32px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .single-item {
    width: 40px;
    height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.grey[650]};
    border-radius: 50%;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    transition-property: border-radius, width, height;
    animation: 0.3s ${rollAnimation};

    > svg {
      font-size: 18px;
      flex-shrink: 0;
    }

    > span {
      display: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 24px;
    }

    &.active {
      background-color: ${({ theme }) => theme.colors.primary.base};
      color: #ffffff;
    }

    &.isTitle {
      border-top: 1px solid ${({ theme }) => theme.colors.grey[300]};
      border-radius: 0;
      height: auto;
      padding: 0;
      margin-top: 8px;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: default;
    }

    .caret {
      display: none;
      margin-left: auto;
      transition: transform 0.2s ease;

      &--open {
        transform: rotate(-180deg);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .body-wrapper {
    flex: 1;
    padding: 0 8px 8px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .footer-wrapper {
    margin-top: auto;
    padding: 10px 8px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 20px;
      right: 20px;
      background-color: ${({ theme }) => theme.colors.grey[300]};
      height: 1px;
      transition: all 0.2s ease;
      transition-property: left, right;
    }
  }

  &:hover,
  &.open {
    background-color: rgba(255, 255, 255, 0.9);

    ${expendedOpenHoverState};
  }

  &.expanded {
    background-color: ${({ theme }) => theme.layout.background};

    ${expendedOpenHoverState};
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    visibility: hidden;
    opacity: 0;

    &.expanded {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export default SidebarWrapper;
