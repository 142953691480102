import { toast } from "shared/providers/toast/toast.package";
import { onError } from "shared/providers/apollo/link/error.package";

import { removeLocalStorageItem } from "shared/utils/local-storage";

import { WEB_AUTH_ADMIN_TOKEN } from "shared/common/data/constants";

const errorLink = (setIsMaintenance: (maintenance: boolean) => void) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err.extensions.code === "UNAUTHENTICATED") {
          removeLocalStorageItem(WEB_AUTH_ADMIN_TOKEN);

          const queryString = window.location.search;
          let loginToken: string | null = null;

          if (queryString) {
            const urlParams = new URLSearchParams(queryString);
            loginToken = urlParams.get("loginToken");
          }

          if (loginToken) {
            window.location.replace(window.location.pathname);
          } else {
            window.location.reload();
          }
        }
      }
    } else if (networkError) {
      console.log(networkError);

      if ("statusCode" in networkError) {
        console.log("Status Code:", networkError.statusCode);
      }

      if (networkError.message.includes("Socket closed")) {
        toast.error(
          `An error occurred while establishing connection with socket. In-app notifications might not work. Error: ${networkError.message}`
        );
      }

      if (
        networkError.message.includes("Failed to fetch") ||
        networkError.message.includes("CORS") ||
        ("statusCode" in networkError && networkError.statusCode === 503)
      ) {
        setIsMaintenance(true);
      }
    }
  });

export default errorLink;
