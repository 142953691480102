import {
  useRef,
  useEffect,
  useCallback,
  KeyboardEvent,
  PropsWithChildren,
} from "react";
import useOnClickOutside from "../../hooks/useOutside.hook";

import ModalWrapper from "./modal.styles";

import { IModalProps } from "./modal.interfaces";

const Modal = ({
  children,
  closeHandler,
  $width = "medium",
  contentWrapperClassName,
  ...rest
}: PropsWithChildren<IModalProps>) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(contentRef, closeHandler);

  const handleEsc = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        closeHandler();
      }
    },
    [closeHandler]
  );

  useEffect(() => {
    const hasStopScroll = document.body.classList.contains("stop-body-scroll");

    contentRef.current?.focus();

    if (!hasStopScroll) {
      document.body.classList.add("stop-body-scroll");
    }

    return () => {
      if (!hasStopScroll) {
        document.body.classList.remove("stop-body-scroll");
      }
    };
  }, []);

  return (
    <ModalWrapper $width={$width} {...rest}>
      <div
        ref={contentRef}
        className={`modal__content-wrapper${
          contentWrapperClassName ? ` ${contentWrapperClassName}` : ""
        }`}
        tabIndex={-1}
        onKeyUp={handleEsc}
      >
        {children}
      </div>
    </ModalWrapper>
  );
};

export default Modal;
