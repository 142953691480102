import styled from "styled-components";

const MainWrapper = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  padding-left: ${({ theme }) => theme.layout.contentXPadding};
  padding-right: ${({ theme }) => theme.layout.contentXPadding};
`;

export default MainWrapper;
