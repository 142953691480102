import {
  TbActivity,
  TbAdjustments,
  TbAirBalloon,
  TbAlertTriangle,
  TbApple,
  TbArmchair,
  TbArrowsRightLeft,
  TbArrowsUpDown,
  TbAtom,
  TbBatteryCharging,
  TbBeach,
  TbBell,
  TbBluetooth,
  TbBolt,
  TbBook2,
  TbBookmark,
  TbBookmarkOff,
  TbBrush,
  TbBucket,
  TbBug,
  TbBuilding,
  TbBuildingBank,
  TbBuildingCommunity,
  TbBuildingSkyscraper,
  TbBulb,
  TbBulldozer,
  TbCactus,
  TbCalculator,
  TbCalendar,
  TbCamera,
  TbCashBanknote,
  TbCast,
  TbCertificate,
  TbChartBar,
  TbChartPie,
  TbCircleCheck,
  TbCirclePlus,
  TbClipboard,
  TbClipboardText,
  TbClock,
  TbCloud,
  TbCode,
  TbCoffee,
  TbCompass,
  TbCreditCard,
  TbCup,
  TbCurrencyDollar,
  TbDatabase,
  TbDeviceDesktop,
  TbDeviceMobile,
  TbDeviceProjector,
  TbDevices,
  TbDiamond,
  TbDoor,
  TbDrone,
  TbExternalLink,
  TbEye,
  TbFile,
  TbFlag,
  TbFlower,
  TbGhost,
  TbGlobe,
  TbHammer,
  TbHeart,
  TbHexagon,
  TbHistory,
  TbHome,
  TbKeyboard,
  TbLanguage,
  TbLink,
  TbLock,
  TbMail,
  TbMapPin,
  TbMedal,
  TbMenuOrder,
  TbMessage,
  TbMicroscope,
  TbMug,
  TbMusic,
  TbNews,
  TbOutlet,
  TbPalette,
  TbPaperclip,
  TbPdf,
  TbPrinter,
  TbPuzzle,
  TbReport,
  TbRuler,
  TbSchool,
  TbScissors,
  TbSend,
  TbSettings,
  TbShieldCheck,
  TbShip,
  TbShoppingCart,
  TbSitemap,
  TbSkull,
  TbSmartHome,
  TbSnowflake,
  TbSofa,
  TbSquareRoot,
  TbStack2,
  TbStarHalfFilled,
  TbSubtask,
  TbSun,
  TbSunset,
  TbTag,
  TbTeapot,
  TbTemperatureMinus,
  TbTemperaturePlus,
  TbTemplate,
  TbTicket,
  TbTool,
  TbTools,
  TbToolsKitchen2,
  TbTree,
  TbTruck,
  TbUmbrella,
  TbUser,
  TbUsers,
  TbVinyl,
  TbWand,
  TbWifi,
} from "react-icons/tb";

// NOTE: Whenever changing anything in icons make sure to adjust also in containers/icon-select/icon-select.utils

const icons = [
  {
    id: "home",
    icon: TbHome,
  },
  {
    id: "link",
    icon: TbLink,
  },
  {
    id: "externalLink",
    icon: TbExternalLink,
  },
  {
    id: "activity",
    icon: TbActivity,
  },
  {
    id: "building",
    icon: TbBuildingSkyscraper,
  },
  {
    id: "buildingSecondary",
    icon: TbBuilding,
  },
  {
    id: "lock",
    icon: TbLock,
  },
  {
    id: "menuSettings",
    icon: TbMenuOrder,
  },
  {
    id: "person",
    icon: TbUser,
  },
  {
    id: "wrench",
    icon: TbTool,
  },
  {
    id: "star",
    icon: TbStarHalfFilled,
  },
  {
    id: "warningAmber",
    icon: TbAlertTriangle,
  },
  {
    id: "integration",
    icon: TbArrowsRightLeft,
  },
  {
    id: "exchange",
    icon: TbArrowsUpDown,
  },
  {
    id: "eye",
    icon: TbEye,
  },
  {
    id: "pdf",
    icon: TbPdf,
  },
  {
    id: "accountBalance",
    icon: TbBuildingBank,
  },
  {
    id: "multiplePersons",
    icon: TbUsers,
  },
  {
    id: "moneyBill",
    icon: TbCashBanknote,
  },
  {
    id: "newspaper",
    icon: TbNews,
  },
  {
    id: "dollar",
    icon: TbCurrencyDollar,
  },
  {
    id: "priceTag",
    icon: TbTag,
  },
  {
    id: "tools",
    icon: TbTools,
  },
  {
    id: "certificate",
    icon: TbShieldCheck,
  },
  {
    id: "printer",
    icon: TbPrinter,
  },
  {
    id: "devices",
    icon: TbDevices,
  },
  {
    id: "chair",
    icon: TbArmchair,
  },
  {
    id: "doors",
    icon: TbDoor,
  },
  {
    id: "book",
    icon: TbBook2,
  },
  {
    id: "history",
    icon: TbHistory,
  },
  {
    id: "report",
    icon: TbReport,
  },
  {
    id: "phone",
    icon: TbDeviceMobile,
  },
  {
    id: "email",
    icon: TbMail,
  },
  {
    id: "document",
    icon: TbFile,
  },
  {
    id: "template",
    icon: TbTemplate,
  },
  {
    id: "cart",
    icon: TbShoppingCart,
  },
  {
    id: "send",
    icon: TbSend,
  },
  {
    id: "calendar",
    icon: TbCalendar,
  },
  {
    id: "stack",
    icon: TbStack2,
  },
  {
    id: "adjust",
    icon: TbAdjustments,
  },
  {
    id: "chart",
    icon: TbChartBar,
  },
  {
    id: "wifi",
    icon: TbWifi,
  },
  {
    id: "coffee",
    icon: TbCoffee,
  },
  {
    id: "outlet",
    icon: TbOutlet,
  },
  {
    id: "highTemp",
    icon: TbTemperaturePlus,
  },
  {
    id: "lowTemp",
    icon: TbTemperatureMinus,
  },
  {
    id: "checkmark",
    icon: TbCircleCheck,
  },
  {
    id: "airplay",
    icon: TbCast,
  },
  {
    id: "card",
    icon: TbCreditCard,
  },
  {
    id: "charging",
    icon: TbBatteryCharging,
  },
  {
    id: "ticket",
    icon: TbTicket,
  },
  {
    id: "electricity",
    icon: TbBolt,
  },
  {
    id: "bluetooth",
    icon: TbBluetooth,
  },
  {
    id: "keyboard",
    icon: TbKeyboard,
  },
  {
    id: "calculator",
    icon: TbCalculator,
  },
  {
    id: "coffePot",
    icon: TbTeapot,
  },
  {
    id: "broom",
    icon: TbBucket,
  },
  {
    id: "clipboard",
    icon: TbClipboard,
  },
  {
    id: "clipboardText",
    icon: TbClipboardText,
  },
  {
    id: "tea",
    icon: TbMug,
  },
  {
    id: "foodBeverages",
    icon: TbCup,
  },
  {
    id: "lightbulb",
    icon: TbBulb,
  },
  {
    id: "projector",
    icon: TbDeviceProjector,
  },
  {
    id: "screen",
    icon: TbDeviceDesktop,
  },
  {
    id: "bookmark",
    icon: TbBookmark,
  },
  {
    id: "database",
    icon: TbDatabase,
  },
  {
    id: "cog",
    icon: TbSettings,
  },
  {
    id: "subTask",
    icon: TbSubtask,
  },
  {
    id: "language",
    icon: TbLanguage,
  },
  {
    id: "airBalloon",
    icon: TbAirBalloon,
  },
  {
    id: "apple",
    icon: TbApple,
  },
  {
    id: "atom",
    icon: TbAtom,
  },
  {
    id: "beach",
    icon: TbBeach,
  },
  {
    id: "bell",
    icon: TbBell,
  },
  {
    id: "bookmarkOff",
    icon: TbBookmarkOff,
  },
  {
    id: "brush",
    icon: TbBrush,
  },
  {
    id: "bug",
    icon: TbBug,
  },
  {
    id: "community",
    icon: TbBuildingCommunity,
  },
  {
    id: "bulldozer",
    icon: TbBulldozer,
  },
  {
    id: "cactus",
    icon: TbCactus,
  },
  {
    id: "camera",
    icon: TbCamera,
  },
  {
    id: "certificateAlt",
    icon: TbCertificate,
  },
  {
    id: "pieChart",
    icon: TbChartPie,
  },
  {
    id: "addCircle",
    icon: TbCirclePlus,
  },
  {
    id: "clock",
    icon: TbClock,
  },
  {
    id: "cloud",
    icon: TbCloud,
  },
  {
    id: "code",
    icon: TbCode,
  },
  {
    id: "compass",
    icon: TbCompass,
  },
  {
    id: "diamond",
    icon: TbDiamond,
  },
  {
    id: "drone",
    icon: TbDrone,
  },
  {
    id: "flag",
    icon: TbFlag,
  },
  {
    id: "flower",
    icon: TbFlower,
  },
  {
    id: "ghost",
    icon: TbGhost,
  },
  {
    id: "globe",
    icon: TbGlobe,
  },
  {
    id: "hammer",
    icon: TbHammer,
  },
  {
    id: "heart",
    icon: TbHeart,
  },
  {
    id: "hexagon",
    icon: TbHexagon,
  },
  {
    id: "mapPin",
    icon: TbMapPin,
  },
  {
    id: "medal",
    icon: TbMedal,
  },
  {
    id: "message",
    icon: TbMessage,
  },
  {
    id: "microscope",
    icon: TbMicroscope,
  },
  {
    id: "music",
    icon: TbMusic,
  },
  {
    id: "palette",
    icon: TbPalette,
  },
  {
    id: "paperclip",
    icon: TbPaperclip,
  },
  {
    id: "puzzle",
    icon: TbPuzzle,
  },
  {
    id: "ruler",
    icon: TbRuler,
  },
  {
    id: "school",
    icon: TbSchool,
  },
  {
    id: "scissors",
    icon: TbScissors,
  },
  {
    id: "ship",
    icon: TbShip,
  },
  {
    id: "sitemap",
    icon: TbSitemap,
  },
  {
    id: "skull",
    icon: TbSkull,
  },
  {
    id: "smartHome",
    icon: TbSmartHome,
  },
  {
    id: "sofa",
    icon: TbSofa,
  },
  {
    id: "snowflake",
    icon: TbSnowflake,
  },
  {
    id: "squareRoot",
    icon: TbSquareRoot,
  },
  {
    id: "sun",
    icon: TbSun,
  },
  {
    id: "sunset",
    icon: TbSunset,
  },
  {
    id: "kitchenTools",
    icon: TbToolsKitchen2,
  },
  {
    id: "tree",
    icon: TbTree,
  },
  {
    id: "truck",
    icon: TbTruck,
  },
  {
    id: "umbrella",
    icon: TbUmbrella,
  },
  {
    id: "vinyl",
    icon: TbVinyl,
  },
  {
    id: "wand",
    icon: TbWand,
  },
];

export default icons;
