import { Fragment, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import ReactTooltip from "react-tooltip";

import Informer from "./tooltip.styles";

import { TbQuestionMark } from "react-icons/tb";

import { ITooltipProps } from "./tooltip.interfaces";

const Tooltip = ({
  children,
  showInformer,
  informerIcon,
  id,
  ...props
}: ITooltipProps) => {
  const tooltipId = useMemo(() => id || uuidv4(), [id]);

  return (
    <Fragment>
      {showInformer ? (
        <Informer data-tip data-for={tooltipId} className="ml-5">
          {informerIcon ? informerIcon : <TbQuestionMark />}
        </Informer>
      ) : null}

      <ReactTooltip
        effect="solid"
        backgroundColor="#ffffff"
        textColor="#000000"
        delayHide={200}
        clickable
        id={tooltipId}
        {...props}
        className={`tooltip-cmp${props.className ? ` ${props.className}` : ""}`}
      >
        {children}
      </ReactTooltip>
    </Fragment>
  );
};

export default Tooltip;
