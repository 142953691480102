import styled from "styled-components";
import { togglerBgStyles } from "../../../styles/Theme/common";

import AvatarWrapper from "../../avatar/avatar.styles";
import { Description } from "../../description";
import { Title } from "../../title";

const TopbarWrapper = styled.header`
  height: ${({ theme }) => theme.layout.topbarHeight};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.layout.topbarBottomMargin};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
  padding-left: ${({ theme }) => theme.layout.contentXPadding};
  padding-right: ${({ theme }) => theme.layout.contentXPadding};

  .user-logout-wrapper {
    padding: 8px;
    border-top: 1px solid ${({ theme }) => theme.colors.grey[300] || "#e6ebf0"};

    svg {
      color: ${({ theme }) => theme.colors.warning.base};
    }

    &:first-child {
      border-top: none;
    }
  }

  .user-menu {
    padding: 8px;
  }

  .user-text {
    margin-right: 15px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      display: none;
    }

    ${Title} {
      line-height: 1.33;
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ${Description} {
      font-size: 12px;
    }
  }

  .user-actions {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    border-radius: 8px;
    cursor: pointer;

    ${({ theme }) => theme.breakpoints.down("md")} {
      > ${AvatarWrapper} {
        margin-right: 0px;
      }
    }

    > svg {
      font-size: 16px;
      transition: transform 0.2s ease;

      ${({ theme }) => theme.breakpoints.down("md")} {
        display: none;
      }
    }

    ${togglerBgStyles};

    &.open {
      > svg {
        transform: rotate(-180deg);
      }
    }
  }

  .custom-action-wrapper {
    margin-right: 15px;
  }

  .custom-action {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.grey[700]};
    background-color: ${({ theme }) => theme.colors.grey[100]};
    border: 1px solid transparent;
    cursor: pointer;
    transition: border-color 0.2s ease;

    > .custom-action-indicator {
      opacity: 0;
      visibility: hidden;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.blue.base};
      position: absolute;
      top: -2px;
      right: -2px;
      transition: opacity 0.2 ease;

      &.visible {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover,
    &.open {
      border-color: ${({ theme }) => theme.colors.grey[300]};
    }
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: auto;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    margin-right: 15px;

    .mobile-hamburger {
      display: none;
      color: ${({ theme }) => theme.colors.grey[700] || "#4C5761"};
      margin-right: 12px;
      font-size: 1.5rem;
      align-items: center;
      cursor: pointer;
    }

    .back-button {
      flex-shrink: 0;
      margin-right: 8px;
      padding: 8px 6px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      border-radius: 4px;
      transition-duration: 0.2s;
      transition-property: border-color, background-color;
      transition-timing-function: ease;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.grey[100]};
        border-color: ${({ theme }) => theme.colors.grey[300]};
      }
    }

    ${Title} {
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
      .mobile-hamburger {
        display: inline-flex;
      }

      ${Title} {
        > svg {
          display: none;
        }
      }
    }
  }
`;

export default TopbarWrapper;
