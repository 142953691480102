import styled from "styled-components";
import rem from "../../utils/rem";

import { IAvatarProps, IAvatarPreviewProps } from "./avatar.interfaces";

export const AvatarPreview = styled.div<IAvatarPreviewProps>`
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: ${({ $size }) =>
    $size === "xxl"
      ? "128px"
      : $size === "xl"
      ? "64px"
      : $size === "l"
      ? "48px"
      : $size === "m"
      ? "32px"
      : $size === "s"
      ? "24px"
      : $size === "xs"
      ? "16px"
      : $size || "48px"};
  width: ${({ $size }) =>
    $size === "xxl"
      ? "128px"
      : $size === "xl"
      ? "64px"
      : $size === "l"
      ? "48px"
      : $size === "m"
      ? "32px"
      : $size === "s"
      ? "24px"
      : $size === "xs"
      ? "16px"
      : $size || "48px"};
  border-radius: 50%;
  color: ${({ $color }) => $color || "#ffffff"};
  font-size: ${({ $size }) =>
    $size === "xxl"
      ? "40px"
      : $size === "xl"
      ? "24px"
      : $size === "l"
      ? "16px"
      : $size === "m"
      ? "12px"
      : $size === "s"
      ? "16px"
      : $size === "xs"
      ? "12px"
      : "16px"};
  font-weight: 600;
  line-height: 1;
  background-color: ${({ $bgColor, theme }) =>
    $bgColor === "primary"
      ? theme.colors.primary.base
      : $bgColor === "secondary"
      ? theme.colors.secondary.base
      : $bgColor === "accent"
      ? theme.colors.accent.base
      : $bgColor === "grey"
      ? theme.colors.grey.base
      : $bgColor === "success"
      ? theme.colors.success.base
      : $bgColor === "warning"
      ? theme.colors.warning.base
      : $bgColor || theme.colors.primary.base};
  border: 1px solid
    ${({ $borderColor, theme }) =>
      $borderColor === "primary"
        ? theme.colors.primary.base
        : $borderColor === "secondary"
        ? theme.colors.secondary.base
        : $borderColor === "accent"
        ? theme.colors.accent.base
        : $borderColor === "grey"
        ? theme.colors.grey.base
        : $borderColor === "success"
        ? theme.colors.success.base
        : $borderColor === "warning"
        ? theme.colors.warning.base
        : $borderColor === "border"
        ? theme.colors.grey[300]
        : $borderColor || theme.colors.primary.base};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: ${({ $hasBoxShadow, theme }) =>
    $hasBoxShadow ? theme.boxShadowSecondary : undefined};
  cursor: ${({ $cursor }) => $cursor || undefined};

  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const AvatarWrapper = styled.div<IAvatarProps>`
  display: inline-flex;
  position: relative;
  flex-shrink: 0;

  input {
    display: none;
  }

  .actions {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: ${rem(12)};
    color: ${({ theme }) => theme.colors.warning.base};
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  }
`;

export default AvatarWrapper;
