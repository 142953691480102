import { useState, PropsWithChildren } from "react";
import { usePopper } from "react-popper";

import DropdownMenuWrapper from "./dropdown-menu.styles";

import { IDropdownMenuProps } from "./dropdown-menu.interfaces";

const DropdownMenu = ({
  referenceRef,
  $width,
  $minWidth,
  $maxHeight,
  as,
  children,
  modifiers,
  className = "pt-8 pr-8 pb-8 pl-8",
}: PropsWithChildren<IDropdownMenuProps>) => {
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(
    null
  );

  const { styles, attributes } = usePopper(referenceRef, popperElement, {
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
      {
        name: "preventOverflow",
      },
    ],
    ...modifiers,
  });

  return (
    <DropdownMenuWrapper
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      $width={$width}
      $minWidth={$minWidth}
      $maxHeight={$maxHeight}
      as={as}
      className={className}
    >
      {children}
    </DropdownMenuWrapper>
  );
};

export default DropdownMenu;
