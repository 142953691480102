import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useOnClickOutside from "../../hooks/useOutside.hook";

import { DropdownMenu, DropdownMenuItem } from "../dropdown-menu";

import DockWrapper, { DockDropdown } from "./dock.styles";

import { TbChevronDown, TbX } from "react-icons/tb";

import { IDockProps } from "./dock.interfaces";

const Dock = ({
  minimizedItems,
  onClick,
  onRemove,
  rightPosition,
}: IDockProps) => {
  const { t } = useTranslation();
  const dockDropdownWrapper = useRef<HTMLDivElement>(null);

  const [dockDropdownToggler, setDockDropdownToggler] =
    useState<HTMLDivElement | null>(null);

  const [menuOpened, setMenuOpened] = useState(false);

  const closeDockMenu = useCallback(() => setMenuOpened(false), []);

  useOnClickOutside(dockDropdownWrapper, closeDockMenu);

  if (!minimizedItems.length) {
    return null;
  }

  return minimizedItems.length > 4 ? (
    <DockDropdown
      ref={dockDropdownWrapper}
      style={{
        right: rightPosition || undefined,
      }}
    >
      <div
        ref={setDockDropdownToggler}
        onClick={() => setMenuOpened((prevState) => !prevState)}
        className={`dock-dropdown-indicator${menuOpened ? " open" : ""}`}
      >
        {t("global.minimizedItems")} {minimizedItems.length}
        {<TbChevronDown />}
      </div>

      {menuOpened ? (
        <DropdownMenu $width="210px" referenceRef={dockDropdownToggler}>
          {minimizedItems.map(({ type, id, title }, idx) => (
            <DropdownMenuItem
              key={idx}
              onClick={() =>
                onClick({
                  id,
                  title,
                  type,
                })
              }
            >
              {title}

              <TbX
                className="mr-0--i ml-auto"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove({
                    id,
                    title,
                    type,
                  });
                }}
              />
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      ) : null}
    </DockDropdown>
  ) : (
    <DockWrapper
      style={{
        right: rightPosition || undefined,
      }}
    >
      {minimizedItems.map(({ type, id, title }) => (
        <li
          key={id}
          onClick={() =>
            onClick({
              id,
              title,
              type,
            })
          }
        >
          {title}

          <TbX
            onClick={(e) => {
              e.stopPropagation();
              onRemove({
                id,
                title,
                type,
              });
            }}
          />
        </li>
      ))}
    </DockWrapper>
  );
};

export default Dock;
