import React, { useCallback, useRef, useState } from "react";

import useOnClickOutside from "../../../hooks/useOutside.hook";

import DropdownMenu from "../dropdown-menu.component";

import DropdownMenuTogglerWrapper from "./toggler.styles";

import { TbDotsVertical } from "react-icons/tb";

import { IDropdownMenuTogglerProps } from "./toggler.interfaces";

const DropdownMenuToggler = ({
  toggler,
  icon,
  children,
  modifiers,
  $menuWidth,
  onTogglerClick,
  ...rest
}: IDropdownMenuTogglerProps) => {
  const togglerContainerRef = useRef<HTMLDivElement>(null);

  const [togglerRef, setTogglerRef] = useState<HTMLSpanElement | null>(null);

  const [menuOpened, setMenuOpened] = useState(false);

  const closeUserMenu = useCallback(
    () => (menuOpened ? setMenuOpened(false) : null),
    [menuOpened]
  );

  useOnClickOutside(togglerContainerRef, closeUserMenu);

  return (
    <DropdownMenuTogglerWrapper {...rest}>
      <div className="dropdown-toggler__container" ref={togglerContainerRef}>
        <div
          ref={setTogglerRef}
          className={`dropdown-toggler${menuOpened ? " open" : ""}`}
          onClick={(e) => {
            setMenuOpened((prevState) => !prevState);

            if (onTogglerClick) {
              onTogglerClick(e);
            }
          }}
        >
          {toggler ? (
            toggler
          ) : icon ? (
            React.createElement(icon)
          ) : (
            <TbDotsVertical />
          )}
        </div>

        {menuOpened ? (
          <DropdownMenu
            referenceRef={togglerRef}
            modifiers={modifiers}
            $width={$menuWidth}
          >
            {children}
          </DropdownMenu>
        ) : null}
      </div>
    </DropdownMenuTogglerWrapper>
  );
};

export default DropdownMenuToggler;
