import styled from "styled-components";

import { ILayoutProps } from "./layout.interfaces";

const Layout = styled.div<ILayoutProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.layout.background};
  min-height: 100vh;
  padding-left: ${({ theme, expanded }) =>
    expanded ? theme.layout.expandedWidth : theme.layout.collapsedWidth};
  padding-bottom: ${({ theme }) => theme.layout.contentBottomPadding};
  transition: padding 0.2s ease;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-left: 0;
    padding-bottom: 0;
  }
`;

export default Layout;
