import { createElement, useCallback, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useOnClickOutside from "../../../hooks/useOutside.hook";

import { Avatar } from "../../avatar";
import { Description } from "../../description";
import { DropdownMenu, DropdownMenuItem } from "../../dropdown-menu";
import { Title } from "../../title";
import { Label } from "../../form";
import { Tooltip } from "../../tooltip";

import TopbarWrapper from "./topbar.styles";

import {
  TbChevronDown,
  TbBell,
  TbLogout,
  TbMenu2,
  TbSearch,
  TbChevronLeft,
} from "react-icons/tb";

import { ITopbarProps } from "./topbar.interfaces";

import getIcon from "../../../utils/get-icon";

/**
 * Control topbar title and icon
 * @param {titleComponent} - If provided always has precedence over title field
 */

const Topbar = <P extends object>({
  titleComponent: TitleComponent,
  titleComponentProps,
  titleComponentLabel,
  title,
  titleIcon,
  titleBackUrl,
  userFirstName,
  userLastName,
  userTitle,
  userPhoto,
  inactive,
  setExpanded,
  logout,
  items,
  hasNewNotifications,
  notifications,
  openGlobalSearch,
  openGlobalSearchTooltip,
  languageSwitcher,
}: ITopbarProps<P>) => {
  const { t } = useTranslation();

  const history = useHistory();

  const notificationsRef = useRef<HTMLDivElement>(null);
  const userActionsRef = useRef<HTMLDivElement>(null);

  const [notificationsToggler, setNotificationsToggler] =
    useState<HTMLDivElement | null>(null);
  const [userActionsToggler, setUserActionsToggler] =
    useState<HTMLDivElement | null>(null);

  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const closeNotifications = useCallback(
    () => (notificationsOpened ? setNotificationsOpened(false) : null),
    [notificationsOpened]
  );
  const closeUserMenu = useCallback(
    () => (userMenuOpened ? setUserMenuOpened(false) : null),
    [userMenuOpened]
  );

  useOnClickOutside(notificationsRef, closeNotifications);
  useOnClickOutside(userActionsRef, closeUserMenu);

  const onLogoutClick = useCallback(() => {
    logout();
    closeUserMenu();
  }, [logout, closeUserMenu]);

  const titleIconComponent = useMemo(() => getIcon(titleIcon), [titleIcon]);

  return (
    <TopbarWrapper>
      {inactive ? null : (
        <div className="title-wrapper">
          <span className="mobile-hamburger" onClick={() => setExpanded(true)}>
            <TbMenu2 />
          </span>

          {TitleComponent ? (
            <div className="display-flex display-flex--a-center">
              {titleComponentLabel ? (
                <Label className="mr-5">{titleComponentLabel}</Label>
              ) : null}

              <TitleComponent {...((titleComponentProps ?? {}) as P)} />
            </div>
          ) : title ? (
            <div className="display-flex display-flex--a-center">
              {titleBackUrl ? (
                <div
                  className="back-button"
                  onClick={() => history.push(titleBackUrl)}
                >
                  <TbChevronLeft />
                </div>
              ) : null}

              <Title level={3}>
                {titleIconComponent ? createElement(titleIconComponent) : null}
                <span>{title}</span>
              </Title>
            </div>
          ) : null}
        </div>
      )}

      <div className="actions-wrapper">
        {!languageSwitcher ? null : (
          <div className="custom-action-wrapper">{languageSwitcher}</div>
        )}

        {!openGlobalSearch ? null : (
          <div className="custom-action-wrapper">
            <div
              className="custom-action"
              onClick={openGlobalSearch}
              data-tip
              data-for="topbar-open-search-tooltip"
            >
              <TbSearch />
            </div>

            {openGlobalSearchTooltip ? (
              <Tooltip id="topbar-open-search-tooltip">
                {openGlobalSearchTooltip}
              </Tooltip>
            ) : null}
          </div>
        )}

        {inactive || !notifications ? null : (
          <div className="custom-action-wrapper" ref={notificationsRef}>
            <div
              ref={setNotificationsToggler}
              className={`custom-action${notificationsOpened ? " open" : ""}`}
              onClick={() => setNotificationsOpened((prevState) => !prevState)}
            >
              <TbBell />

              <div
                className={
                  hasNewNotifications
                    ? "custom-action-indicator visible"
                    : "custom-action-indicator"
                }
              />
            </div>

            {notificationsOpened ? (
              <DropdownMenu
                as="div"
                referenceRef={notificationsToggler}
                className="pt-0 pr-0 pb-0 pl-0"
              >
                {notifications}
              </DropdownMenu>
            ) : null}
          </div>
        )}

        <div ref={userActionsRef}>
          <div
            ref={setUserActionsToggler}
            onClick={() => setUserMenuOpened((prevState) => !prevState)}
            className={`user-actions${userMenuOpened ? " open" : ""}`}
          >
            <Avatar $size="m" $bgImg={userPhoto} className="mr-12">
              {userPhoto
                ? null
                : `${userFirstName.charAt(0)}${userLastName.charAt(0)}`}
            </Avatar>

            <div className="user-text">
              <Title as="h6" level={6}>
                {`${userFirstName} ${userLastName}`}
              </Title>

              {userTitle ? <Description>{userTitle}</Description> : null}
            </div>

            <TbChevronDown />
          </div>

          {userMenuOpened ? (
            <DropdownMenu
              as="div"
              referenceRef={userActionsToggler}
              className="pt-0 pr-0 pb-0 pl-0"
              $width="200px"
            >
              {inactive || !items?.length ? null : (
                <ul className="user-menu">
                  {items.map((singleItem, idx) => (
                    <DropdownMenuItem
                      key={idx}
                      onClick={singleItem.onClick}
                      icon={singleItem.icon}
                    >
                      {singleItem.title}
                    </DropdownMenuItem>
                  ))}
                </ul>
              )}

              <div className="user-logout-wrapper">
                <DropdownMenuItem as="div" onClick={onLogoutClick}>
                  <TbLogout /> {t("global.logoutAction")}
                </DropdownMenuItem>
              </div>
            </DropdownMenu>
          ) : null}
        </div>
      </div>
    </TopbarWrapper>
  );
};

export default Topbar;
