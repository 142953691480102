import styled from "styled-components";
import {
  dropdownItemDisabledStyles,
  dropdownItemHoverActiveStyles,
  dropdownItemStyles,
} from "../../../styles/Theme/common";

const DropdownMenuItemWrapper = styled.li`
  ${dropdownItemStyles};

  &.active,
  &:hover,
  &:active,
  &:focus {
    ${dropdownItemHoverActiveStyles};
  }

  &.disabled {
    ${dropdownItemDisabledStyles};
  }
`;
export default DropdownMenuItemWrapper;
