import { AvatarPreview } from "../avatar.styles";
import { Modal } from "../../modal";
import { Button } from "../../button";
import { Description } from "../../description";
import { Title } from "../../title";

import { TbX } from "react-icons/tb";

import { IAvatarZoomViewProps } from "./zoom-view.interfaces";

const AvatarZoomView = ({
  $bgImg,
  $bgColor,
  $borderColor,
  title,
  description,
  closeModal,
}: IAvatarZoomViewProps) => {
  return (
    <Modal $width="365px" closeHandler={closeModal}>
      <div className="display-flex display-flex--a-center pt-12 pr-16 pb-12 pl-16">
        <div className="ml-auto">
          <Button
            $size="small"
            variant="secondary"
            onClick={closeModal}
            iconOnly
          >
            <TbX />
          </Button>
        </div>
      </div>

      <div className="display-flex display-flex--column display-flex--a-center display-flex--v-center pr-16 pb-16 pl-16">
        <AvatarPreview
          $bgColor={$bgColor}
          $borderColor={$borderColor}
          $size="160px"
          className="mb-6"
          style={{
            backgroundImage: $bgImg ? `url(${$bgImg})` : undefined,
          }}
        />

        {title ? (
          <Title level={4} as="h6" className="mb-6 display-flex--j-center">
            {title}
          </Title>
        ) : null}

        {description ? <Description>{description}</Description> : null}
      </div>
    </Modal>
  );
};

export default AvatarZoomView;
