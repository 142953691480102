import {
  Fragment,
  ChangeEvent,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { DropdownMenuToggler, DropdownMenuItem } from "../dropdown-menu";

import AvatarWrapper, { AvatarPreview } from "./avatar.styles";
import ZoomView from "./zoom-view/zoom-view.component";

import { TbEye, TbPencil, TbTrash } from "react-icons/tb";

import { IAvatarProps } from "./avatar.interfaces";

const Avatar = forwardRef<HTMLDivElement, IAvatarProps>(
  (
    {
      onFileChange,
      onDelete,
      accept,
      $cursor,
      $bgImg,
      $bgColor,
      $borderColor,
      $color,
      $size,
      $hasBoxShadow,
      children,
      withZoom,
      zoomTitle,
      zoomDescription,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [imageOpen, setImageOpen] = useState(false);

    const handleFileChange = useCallback(
      async (e: ChangeEvent<HTMLInputElement>) => {
        if (onFileChange && e.target.files?.length) {
          const file = e.target.files[0];

          onFileChange(file);
        }
      },
      [onFileChange]
    );

    const openImageModal = useCallback(() => setImageOpen(true), []);

    const closeImageModal = useCallback(() => setImageOpen(false), []);

    return (
      <Fragment>
        <AvatarWrapper {...props} ref={ref}>
          <input
            type="file"
            accept={accept || "image/png, image/jpeg"}
            ref={inputRef}
            onChange={handleFileChange}
          />

          <AvatarPreview
            $hasBoxShadow={$hasBoxShadow}
            $cursor={$cursor}
            $bgColor={$bgColor}
            $borderColor={$borderColor}
            $color={$color}
            $size={$size}
            onClick={() => {
              if (onFileChange) {
                inputRef.current?.click();
              }
            }}
            style={{
              backgroundImage: $bgImg ? `url(${$bgImg})` : undefined,
            }}
          >
            {children}
          </AvatarPreview>

          {$bgImg && (withZoom || onDelete) ? (
            <div className="actions">
              <DropdownMenuToggler
                modifiers={{
                  placement: "bottom-start",
                }}
              >
                {withZoom ? (
                  <DropdownMenuItem
                    id="zoom-avatar"
                    icon={TbEye}
                    onClick={openImageModal}
                  >
                    {t("global.viewAction")}
                  </DropdownMenuItem>
                ) : null}

                <DropdownMenuItem
                  id="update-avatar"
                  icon={TbPencil}
                  onClick={() => inputRef.current?.click()}
                >
                  {t("global.updateAction")}
                </DropdownMenuItem>

                {onDelete ? (
                  <DropdownMenuItem
                    id="remove-avatar"
                    icon={TbTrash}
                    onClick={onDelete}
                  >
                    {t("global.deleteAction")}
                  </DropdownMenuItem>
                ) : null}
              </DropdownMenuToggler>
            </div>
          ) : null}
        </AvatarWrapper>

        {imageOpen ? (
          <ZoomView
            $bgImg={$bgImg}
            $bgColor={$bgColor}
            $borderColor={$borderColor}
            title={zoomTitle}
            description={zoomDescription}
            closeModal={closeImageModal}
          />
        ) : null}
      </Fragment>
    );
  }
);

Avatar.displayName = "Avatar";

export default Avatar;
